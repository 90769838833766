<template v-once>
  <img src="@/assets/icons/lahlouh_icon.png" alt="lahlouhIcon">
</template>

<script>
export default {
  name: "Lahlouh",
};
</script>

<style scoped>
</style>