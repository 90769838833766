import { render, staticRenderFns } from "./sermo.vue?vue&type=template&id=4ec20b40&scoped=true&v-once=true"
import script from "./sermo.vue?vue&type=script&lang=js"
export * from "./sermo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ec20b40",
  null
  
)

export default component.exports