<template v-once>
  <img src="@/assets/icons/doximity_icon.png" alt="doximity icon">
</template>

<script>
export default {
  name: "Doximity",
};
</script>

<style scoped>
</style>