<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g stroke="#66788E" stroke-width="1.5">
        <g transform="translate(-1014 -551) translate(1015 552)">
          <rect width="9.1" height="9.1" x="4.9" y="4.9" rx="1.5" />
          <path d="M2.1 9.1h-.7C.627 9.1 0 8.473 0 7.7V1.4C0 .627.627 0 1.4 0h6.3c.773 0 1.4.627 1.4 1.4v.7" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "Copy"
}
</script>

<style scoped>

</style>
