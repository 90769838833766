<template v-once>
  <img src="@/assets/icons/squint_metrics.png" alt="squint metrics icon">
</template>

<script>
export default {
  name: "Squintmetrics",
};
</script>

<style scoped>
</style>
