<template v-once>
  <img src="@/assets/icons/pulsepoint_life_icon.png" alt="Pulse Point Life">
</template>

<script>
export default {
  name: "PulsePointLife",
};
</script>

<style scoped>
</style>
