<template v-once>
  <img src="@/assets/icons/haymarket_icon.png" alt="haymarket icon">
</template>

<script>
export default {
  name: "Haymarket",
};
</script>

<style scoped>
</style>