export default {
  AddDelay,
  AddDelayWhite,
  AddToSegment,
  AddToSegmentWhite,
  ChatWhite,
  ChatGreen,
  CheckField,
  CheckFieldWhite,
  Eject,
  EjectWhite,
  EmailSplitTest,
  EmailSplitTestWhite,
  RemoveFromSegment,
  RemoveFromSegmentWhite,
  ResendEmail,
  ResendEmailWhite,
  Segment,
  SegmentWhite,
  UpdateField,
  Mail,
  MailPurple,
  MailWhite,
  CheckEmailStatus,
  IsInSegment,
  Eye,
  Trash,
  Pencil
};

import * as AddDelay from "./AddDelay.svg";
import * as AddDelayWhite from "./AddDelayWhite.svg";
import * as AddToSegment from "./AddToSegment.svg";
import * as AddToSegmentWhite from "./AddToSegmentWhite.svg";
import * as ChatWhite from "./chat.svg";
import * as ChatGreen from "./chatGreen.svg";
import * as CheckField from "./CheckField.svg";
import * as CheckFieldWhite from "./CheckFieldWhite.svg";
import * as Eject from "./Eject.svg";
import * as EjectWhite from "./EjectWhite.svg";
import * as EmailSplitTest from "./EmailSplitTest.svg";
import * as EmailSplitTestWhite from "./EmailSplitTestWhite.svg";
import * as RemoveFromSegment from "./RemoveFromSegment.svg";
import * as RemoveFromSegmentWhite from "./RemoveFromSegmentWhite.svg";
import * as ResendEmail from "./ResendEmail.svg";
import * as ResendEmailWhite from "./ResendEmailWhite.svg";
import * as Segment from "./Segment.svg";
import * as SegmentWhite from "./SegmentWhite.svg";
import * as UpdateField from "./UpdateField.svg";
import * as Mail from "./mail.svg";
import * as MailPurple from "./mailPurple.svg";
import * as MailWhite from "./mailWhite.svg";
import * as CheckEmailStatus from "./CheckEmailStatus.svg";
import * as IsInSegment from "./IsInSegment.svg";
import * as Eye from "./eye.svg";
import * as Trash from "./trash.svg";
import * as Pencil from "./pencil.svg";
