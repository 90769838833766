<template v-once>
  <img src="@/assets/icons/mckesson_icon.png" alt="mckesson icon">
</template>

<script>
export default {
  name: "McKesson",
};
</script>

<style scoped>
</style>