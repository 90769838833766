<template v-once>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g stroke="#D6E9F9">
        <g>
          <path d="M12.511 8.599c-1.083 2.562-3.743 4.087-6.501 3.727C3.25 11.966 1.07 9.812.68 7.057.29 4.303 1.784 1.627 4.333.514" transform="translate(-19 -495) translate(19 496)" />
          <path d="M13 6.19c0-1.641-.652-3.216-1.813-4.377C10.026.653 8.45 0 6.81 0v6.19H13z" transform="translate(-19 -495) translate(19 496)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "PieChart"
}
</script>

<style scoped>

</style>
