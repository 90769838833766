<template v-once>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="4"
    viewBox="0 0 9 4"
    class="chevron"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g>
        <g>
          <path
            d="M0 0L3.75 3.75 7.5 0"
            transform="translate(-163 -167) translate(164 167)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ChevronDownThin",
};
</script>

<style scoped>
.g {
  stroke: #D6E9F9
}
.chevron g {
  stroke: currentColor
}
</style>
