<template v-once>
  <img src="@/assets/icons/dcc_icon.png" alt="dcc icon">
</template>

<script>
export default {
  name: "Dcc",
};
</script>

<style scoped>
</style>