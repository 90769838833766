<template v-once>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13">
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g stroke="#D6E9F9">
        <g transform="translate(-19 -456) translate(20 457)">
          <path d="M0 0H8.864V7.682H0zM8.864 2.955L11.227 2.955 13 4.727 13 7.682 8.864 7.682z" />
          <circle cx="2.659" cy="9.159" r="1.477" />
          <circle cx="10.341" cy="9.159" r="1.477" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "Truck"
}
</script>

<style scoped>

</style>
