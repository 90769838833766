<template v-once>
  <img src="@/assets/icons/bulletin_healthcare_icon.png" alt="healthcareIcon">
</template>

<script>
export default {
  name: "Bulletin",
};
</script>

<style scoped>
</style>