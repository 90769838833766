<template v-once>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13">
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g stroke="#D6E9F9">
        <g>
          <path d="M12 8c0 .736-.597 1.333-1.333 1.333h-8L0 12V1.333C0 .597.597 0 1.333 0h9.334C11.403 0 12 .597 12 1.333V8z" transform="translate(-18 -371) translate(19 372)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "Messages"
}
</script>

<style scoped>

</style>
