<template v-once>
  <svg
    height="512px"
    viewBox="0 0 512 512"
    width="512px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>favicon</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="favicon" fill-rule="nonzero">
        <path
          id="Path"
          d="M421.549322,90.4382323 C377.743344,46.3933952 318.138586,21.7022361 256.018669,21.8674705 L256.018669,-4.26325641e-14 C324.384073,-4.26325641e-14 388.667218,26.6280325 437.019593,74.9804074 L421.549322,90.4382323 Z"
          fill="#F1AD00"
        />
        <path
          id="Path"
          d="M256,512 C114.857115,512 2.84217094e-14,397.161554 2.84217094e-14,256 C2.84217094e-14,114.838446 114.857115,-2.84217094e-14 256,-2.84217094e-14 L256,21.8861393 C126.910983,21.8861393 21.8799164,126.910983 21.8799164,256 C21.8799164,385.089017 126.910983,490.120084 256,490.120084 L256,512 Z" fill="#327882"
        />
        <path
          id="Path"
          d="M512,256 L490.101415,256 C490.281294,193.871729 465.596166,134.254096 421.549322,90.4382323 L437.019593,74.9617385 C485.371968,123.332782 512,187.62215 512,256 Z" fill="#0D002D"
        />
        <path
          id="Path"
          d="M256,512 L256,490.120084 C385.09524,490.120084 490.101415,385.09524 490.101415,256 L511.981331,256 C512,397.161554 397.161554,512 256,512 Z" fill="#8CAAAD"
        />
        <path
          id="Path"
          d="M162.05202,148.716223 L187.939521,148.716223 L240.374155,315.086976 C247.094949,334.913316 249.447226,349.705285 249.783266,364.833293 L261.208615,364.833293 C261.544655,350.377364 264.911274,334.24746 270.623949,316.431134 L324.066702,148.703778 L349.94798,148.703778 L273.312266,384 L237.679615,384 L162.05202,148.716223 Z" fill="#0D002D"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "VuMedi",
};
</script>

<style scoped>
path {
  fill: white;
}
</style>
