export const publicRoutes = [
  {
    path: "/",
    name: "Index"
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/Signin"),
    meta: { public: true }
  },
  {
    path: "/password_reset/:token",
    name: "PasswordReset",
    component: () => import("@/views/Login/PasswordReset"),
    meta: { public: true },
    props: true
  },
  {
    path: "/password_reset",
    name: "PasswordResetRequest",
    component: () => import("@/views/Login/PasswordResetRequest"),
    meta: { public: true },
    props: true
  },
  {
    path: "/view_in_browser/:accountId/:campaignTrackingToken/:emailToken",
    name: "ViewInBrowser",
    component: () => import("@/views/ViewInBrowser/ViewInBrowser.vue"),
    meta: { public: true },
    props: true
  },
  {
    path: "/unsubscribe/:accountId/:campaignTrackingToken/:emailToken",
    name: "Unsubscribe",
    meta: { public: true },
    props: true,
    component: () => import("@/views/Unsubscribe/UnsubscribeView"),
  },
  {
    path: "/change_details/:accountId/:campaignTrackingToken/:emailToken",
    name: "ChangeUnsubscribeDetails",
    meta: { public: true },
    props: true,
    component: () => import("@/views/Unsubscribe/UnsubscribeView"),
  },
  {
    path: "/verify-from-line/:accountId/:token",
    name: "VerifyFromLine",
    meta: { public: true },
    props: true,
    component: () => import("@/views/Campaigns/VerifyFromLineView"),
  },
];

export const protectedRoutes = [
  {
    path: "/kn-base",
    name: "KnowledgeBase",
    beforeEnter() {window.open('https://kb.pulsehealth.tech/', '_blank')}
  },
  {
    path: "/phone/validation",
    name: "PhoneConfirm",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Login/PhoneConfirm"),
  },
  {
    path: "/phone/code/validation",
    name: "PhoneCodeConfirm",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Login/PhoneCodeConfirm"),
  },
  {
    path: "/accounts",
    name: "Accounts",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Accounts/AccountsView"),
  },
  {
    path: "/contacts/search/:searchId?",
    name: "ContactsSearch",
    meta: { protected: true, account_needed: true },
    props: route => ({ ...route.query, ...route.params }),
    component: () => import("@/views/Contacts/SearchView")
  },
  {
    path: "/contacts/:id/similar",
    name: "FindSimilar",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Contacts/FindSimilar"),
  },
  {
    path: "/contacts/add",
    name: "AddContact",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Contacts/AddContact"),
  },
  {
    path: "/contacts/add_single",
    name: "AddSingleContact",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Contacts/AddSingleContact"),
  },
  {
    path: "/contacts/bulk_import",
    name: "BulkImportContact",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Contacts/BulkImportContact"),
  },
  {
    path: "/contacts/match_tool",
    name: "ContactMatchTool",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Contacts/ContactMatchTool"),
  },
  {
    path: "/contacts/:id",
    name: "ContactDetails",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Contacts/ContactProfile.vue"),
  },
  {
    path: "/contacts/:id/edit",
    name: "EditContact",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Contacts/EditContact"),
  },
  {
    path: "/global_deployments",
    name: "GlobalDeployments",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/GlobalDeployments/CampaignsCalendarView"),
  },
  {
    path: "/npi_match",
    name: "NpiMatch",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/NpiMatch/NpiMatchView"),
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Logout"),
  },
  {
    path: "/segments/search",
    name: "SegmentsSearch",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Segments/SegmentsView"),
  },
  {
    path: "/campaigns/search",
    name: "CampaignsSearch",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/CampaignsView"),
  },
  {
    path: "/media_library",
    name: "MediaLibrary",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/MediaLibrary/MediaLibraryView"),
  },
  {
    path: "/campaigns/simple/:id",
    name: "SimpleCampaign",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/SimpleCampaignView"),
  },
  {
    path: "/campaigns/schedule/:id",
    name: "ScheduleCampaign",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/ScheduleCampaignView"),
  },
  {
    path: "/campaigns/fromlines",
    name: "FromLines",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/FromLinesView"),
  },
  {
    path: "/campaigns/automated/:id",
    name: "AutomatedCampaign",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/AutomationCampaign"),
  },
  {
    path: "/leadScoreRules",
    name: "LeadScoreRules",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/LeadScore/LeadScoreRulesView"),
  },
  {
    path: "/api_tokens",
    name: "ApiTokens",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/ApiTokens/ApiTokensView"),
  },
  {
    path: "/edit_user/:id",
    name: "ManageUser",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Users/ManagerUserView"),
  },
  {
    path: "/new_user",
    name: "NewUser",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Users/ManagerUserView"),
  },
  {
    path: "/my_profile",
    name: "MyProfile",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Users/MyProfileView"),
  },
  {
    path: "/user",
    name: "ManagerUserList",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Users/ManagerUserListView"),
  },
  {
    path: "/database",
    name: "ManagerDatabaseList",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Databases/ManagerDatabaseListView"),
  },
  {
    path: "/campaigns/creatives",
    name: "EmailCreatives",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/EmailCreativesView"),
  },
  {
    path: "/campaigns/creatives/:id",
    name: "EditEmailCreative",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/EditEmailCreativeView"),
  },
  {
    path: "/web_tracking",
    name: "WebTrackingScripts",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/WebTracking/WebTrackingScriptsView"),
  },
  {
    path: "/reports/email",
    name: "EmailReport",
    meta: { protected: true, account_needed: true },
    props: route => {
      let selectedCampaignIDs, selectedEmailSendIDs, selectedSegmentIDs = [];

      const queryCampaignIDs = route.query?.selectedCampaignIDs;
      const queryEmailSendIDs = route.query?.selectedEmailSendIDs;
      const querySegmentIDs = route.query?.selectedSegmentIDs;

      if (queryCampaignIDs) {
        selectedCampaignIDs = Array.isArray(queryCampaignIDs)
            ? queryCampaignIDs?.map(id => parseInt(id))
            : [parseInt(queryCampaignIDs)];
      }
      if (queryEmailSendIDs) {
        selectedEmailSendIDs = Array.isArray(queryEmailSendIDs)
            ? queryEmailSendIDs.slice(0, 5)?.map(id => parseInt(id))
            : [parseInt(queryEmailSendIDs)];
      }
      if (querySegmentIDs && queryEmailSendIDs.length <= 3) {
        selectedSegmentIDs = Array.isArray(querySegmentIDs)
            ? querySegmentIDs.slice(0, 5).map(id => parseInt(id))
            : [parseInt(querySegmentIDs)];
      }

      return {
        selectedCampaignIDs,
        selectedEmailSendIDs,
        selectedSegmentIDs,
      };
    },
    component: () => import("@/views/Reports/Email/EmailReport"),
  },
  {
    path: "/dashboard",
    name: "DashboardList",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Dashboards/DashboardListView"),
  },
  {
    path: "/new_account",
    name: "NewAccount",
    meta: { protected: true },
    props: true,
    component: () => import("@/views/Accounts/NewAccountView"),
  },
  {
    path: "/edit_account/:id",
    name: "EditAccount",
    meta: { protected: true, private: ['ROLE_PULSE_ADMIN'] },
    props: true,
    component: () => import("@/views/Accounts/EditAccountView"),
  },
  {
    path: "/personalization_settings/:id",
    name: "PersonalizationSettings",
    meta: { protected: true, private: ['ROLE_PULSE_ADMIN'] },
    props: true,
    component: () => import("@/views/Accounts/PersonalizationSettingsView"),
  },
  {
    path: "/source",
    name: "Sources",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Contacts/SourcesView"),
  },
  {
    path: "/dashboard/:id/edit",
    name: "EditDashboard",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Dashboards/EditDashboard"),
  },
  {
    path: "/dashboard/:id",
    name: "DashboardView",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Dashboards/DashboardView"),
  },
  {
    path: "/blacklist",
    name: "Blacklist",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/BlackListView"),
  },
  {
    path: "/channel/alerts",
    name: "Alerts",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Channels/AlertView"),
  },
  {
    path: "/channel/banners",
    name: "Banners",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Channels/BannerView"),
  },
  {
    path: "/channel/direct_mail",
    name: "DirectMail",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Channels/DirectMailView"),
  },
  {
    path: "/channel/events_conferences",
    name: "Events",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Channels/EventsView"),
  },
  {
    path: "/channel/forms",
    name: "Forms",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Channels/FormView"),
  },
  {
    path: "/channel/forms/create",
    name: "CreateForm",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Channels/EditFormView"),
  },
  {
    path: "/channel/forms/:id/edit",
    name: "EditForm",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Channels/EditFormView"),
  },
  {
    path: "/campaigns/bulk_unsubscribe",
    name: "BulkUnsubscribe",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Campaigns/BulkUnsubscribeView"),
  },
  {
    path: "/reports/banner",
    name: "BannerReport",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Reports/Banner/BannerReportView"),
  },
  {
    path: "/reports",
    name: "Reports",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Reports/ReportsView"),
  },
  {
    path: "/exports",
    name: "ExportsView",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Exports/ExportsView"),
  },
  {
    path: "/imports",
    name: "ImportsView",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Imports/ImportsView"),
  },
  {
    path: "/actions/search",
    name: "SearchActions",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Actions/SearchActions"),
  },
  {
    path: "/actions/import",
    name: "ImportActions",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Actions/ImportActions"),
  },
  {
    path: "/reports/email_subscription_status",
    name: "EmailSubscriptionStatusReport",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Reports/EmailSubscriptionStatusReport"),
  },
  {
    path: "/reports/engagement",
    name: "EngagementReport",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Reports/EngagementReportView"),
  },
  {
    path: "/imports/:id/contact_import_reviews",
    name: "ContactImportProcessView",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Imports/ContactImportProcessView"),
  },
];