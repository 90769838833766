<template>
  <v-list-item class="pa-0">
    <v-menu
      class="ml-2"
      :close-on-content-click="false"
      nudge-left="-8px"
      offset-x
    >
      <template #activator="{ on, attrs }">
        <v-list-item icon color="white" dark v-bind="attrs" v-on="on">
          <v-icon size="13px">
            {{ icon }}
          </v-icon>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
      </template>
      <v-list class="out-menu-navigation" dark>
        <v-list-item class="out-menu-navigation__header">
          <v-list-item-content>
            <v-list-item-title>
              {{ title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <template
          v-for="subroute in items"
        >
          <v-list-item
            v-if="subroute.canView == undefined || subroute.canView"
            :key="subroute.title"
            link
            @click="$router.push({ name: subroute.path, params: subroute.params })"
          >
            <v-list-item-content>
              <v-list-item-title>{{ subroute.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuMobileCollapseItem",
  props: {
    title: {
      default: () => "",
      type: String,
    },
    icon: {
      default: () => "",
      type: String,
    },
    items: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-list.out-menu-navigation {
  padding-top: 0 !important;

  .v-list-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d6e9f9;
  }

  &__header {
    .v-list-item__title {
      color: white;
      font-weight: 600;
    }
  }
  .v-divider.theme--dark {
    background: #063d69;
    border-top: 1px solid #063d69;
  }
}
</style>
