import store from './index'

function subscribe(topicUrl) {
  const url = new URL(process.env.VUE_APP_MERCURE_ADDRESS + ".well-known/mercure");
  url.searchParams.append(
      "topic",
      `${process.env.VUE_APP_MERCURE_ADDRESS}${topicUrl}`
  );

  // Check local storage for a Last Event ID
  // By sending Last Event ID, it will automatically receive any updates since this ID and this should
  // speed up the loading of data
  // This is not perfect, for example if the user reloads the dashboard before new updates are published
  // nothing will be returned by the subscription like normal
  let events = localStorage.getItem('events');
  if (events) {
    events = JSON.parse(events);

    if (events[topicUrl]) {
      url.searchParams.append('Last-Event-ID', events[topicUrl])
    }
  }

  return new EventSource(
      url,
      {withCredentials: true}
  );
}

function updateLastEventId(topicUrl, lastEventId) {
  // Save the last event ID to local storage
  // This will be used next time the app subscribes to this dashboard
  // By sending Last Event ID, it will automatically receive any updates since this ID and this should
  // speed up the loading of data
  // This is not perfect, for example if the user reloads the dashboard before new updates are published
  // nothing will be returned by the subscription like normal
  let events = localStorage.getItem('events');
  if (!events) {
    events = {};
  } else {
    events = JSON.parse(events);
  }
  events[topicUrl] = lastEventId;
  localStorage.setItem('events', JSON.stringify(events));
}

export const mercure = {
  namespaced: true,
  state: {
    subscriptions: []
  },
  mutations: {
    set_connection(state, subscription) {
      state.subscriptions = [...state.subscriptions, subscription];
    },
    delete_connection(state, subscription) {
      if (state.subscriptions.length === 0) return;
      state.subscriptions = [
        ...state.subscriptions.filter(i => {
          return i.url !== subscription.url;
        })
      ];
    }
  },
  actions: {
    unsubscribe({ commit }, stream) {
      stream.close();
      commit("delete_connection", stream);
    },
    dashboard_subscribe({ commit }, dashboardId) {
      const account = store.getters['user/account'];
      if (!account || !account.accountId) {
        return null;
      }

      let stream = subscribe(account.accountId + `/dashboards/${dashboardId}`);
      commit("set_connection", stream);
      return stream;
    },
    update_dashboard_last_event(store, event) {
      updateLastEventId(`dashboard/${event.dashboardId}`, event.lastEventId);
    },
    exports_subscribe({ commit }) {
      const account = store.getters['user/account'];
      if (!account || !account.accountId) {
        return null;
      }

      let stream = subscribe(account.accountId + '/exports/{exportType}/{id}');
      commit("set_connection", stream);
      return stream;
    },
    update_exports_last_event(store, lastEventId) {
      updateLastEventId('exports/{exportType}/{id}', lastEventId);
    },
    export_type_subscribe({ commit }, exportType) {
      const account = store.getters['user/account'];
      if (!account || !account.accountId) {
        return null;
      }

      let stream = subscribe(account.accountId + `/exports/${exportType}/{id}`);
      commit("set_connection", stream);
      return stream;
    },
    update_export_type_last_event(store, exportData) {
      updateLastEventId(`exports/${exportData.exportType}/{id}`, exportData.lastEventId);
    },
    imports_subscribe({ commit }) {
      const account = store.getters['user/account'];
      if (!account || !account.accountId) {
        return null;
      }

      let stream = subscribe(account.accountId + '/imports/{importType}/{id}');
      commit("set_connection", stream);
      return stream;
    },
    update_imports_last_event(store, lastEventId) {
      updateLastEventId('imports/{importType}/{id}', lastEventId);
    },
    import_type_subscribe({ commit }, importType) {
      const account = store.getters['user/account'];
      if (!account || !account.accountId) {
        return null;
      }

      let stream = subscribe(account.accountId + `/imports/${importType}/{id}`);
      commit("set_connection", stream);
      return stream;
    },
    update_import_type_last_event(store, importData) {
      updateLastEventId(`imports/${importData.importType}/{id}`, importData.lastEventId);
    },
    import_subscribe({ commit }, importData) {
      const account = store.getters['user/account'];
      if (!account || !account.accountId) {
        return null;
      }

      let stream = subscribe(account.accountId + `/imports/${importData.importType}/${importData.id}`);
      commit("set_connection", stream);
      return stream;
    },
    update_import_last_event(store, importData) {
      updateLastEventId(`imports/${importData.importType}/${importData.id}`, importData.lastEventId);
    },
    account_status_subscribe({ commit }, accountId) {
      let stream = subscribe(`accounts/${accountId}`);
      commit("set_connection", stream);
      return stream;
    },
    update_account_status_last_event(store, accountId) {
      updateLastEventId(`accounts/${accountId}`);
    },
  },
  getters: {
    subscriptions: state => ({ ...state.subscriptions })
  }
};
