<template v-once>
  <img src="@/assets/icons/epocrates_icon.png" alt="epocrates icon">
</template>

<script>
export default {
  name: "Epocrates",
};
</script>

<style scoped>
</style>