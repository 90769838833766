<template>
  <v-app-bar
    v-if="!$route.meta.public && $route.path !== '/phone/validation' && $route.path !== '/phone/code/validation'"
    class="elevation-0 header-bar"
    app
    :height="$vuetify.breakpoint.smAndDown ? '78px' : '58px'"
  >
    <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex align-center w-100 px-2">
      <div class="d-flex flex-row flex-1">
        <div class="menu-btn">
          <MobileNavigationButton class="mr-1" />
        </div>
        <AccountImage class="flex-1 d-flex align-center" />
      </div>
      <v-spacer />
      <!--<NotificationsMenu :items="notifications" class="pr-3" />-->

      <v-menu nudge-top="-5px" nudge-left="125px" :offset-y="true">
        <template #activator="{ on, attrs }">
          <v-btn
            class="px-0 elevation-0"
            color="#08497e"
            elevation="17"
            height="36px"
            width="36px"
            max-width="36px"
            fab
            v-bind="attrs"
            v-on="on"
          >
            <img
              v-if="$store.getters['user/user_info'].gravatarUrl"
              height="40px"
              :src="$store.getters['user/user_info'].gravatarUrl"
              class="img-avatar"
            >
            <v-icon v-else color="white">
              mdi-account-outline
            </v-icon>
            <!--<template  #menu/> TODO: check if some details missing?-->
          </v-btn>
        </template>
        <OuterList
          title="Settings"
          :items="actualOuterListItems"
          @click="(item) => $router.push({ name: item.route })"
        />
      </v-menu>
    </div>

    <div v-else class="d-flex justify-end w-100">
      <NavigationDuplicate />
      <v-spacer />
      <!--<NotificationsMenu :items="notifications" class="mx-3" />-->
      <AccountImage class="d-flex align-center mx-3" />

      <div class="pr-sm-10 ml-3 d-flex align-center">
        <v-menu nudge-top="-5px" nudge-left="125px" :offset-y="true">
          <template #activator="{ on, attrs }">
            <v-btn
              class="px-0 elevation-0"
              color="#08497e"
              elevation="17"
              height="36px"
              width="36px"
              max-width="36px"
              fab
              v-bind="attrs"
              v-on="on"
            >
              <img
                v-if="$store.getters['user/user_info'].gravatarUrl"
                height="40px"
                :src="$store.getters['user/user_info'].gravatarUrl + '?d=mp'"
                class="img-avatar"
              >
              <v-icon v-else color="white">
                mdi-account-outline
              </v-icon>
            </v-btn>
          </template>
          <OuterList
            title="Settings"
            :items="actualOuterListItems"
            @click="(item) => $router.push({ name: item.route })"
          />
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import MobileNavigationButton from "@/sharedComponents/header/components/MobileNavigationButton";
import NavigationDuplicate from "@/sharedComponents/header/components/NavigationDuplicate";
import AccountImage from "@/sharedComponents/header/components/AccountImage";
import OuterList from "@/sharedComponents/OuterList";
export default {
  name: "Header",
  components: {
    AccountImage,
    NavigationDuplicate,
    MobileNavigationButton,
    OuterList,
  },
  data() {
    return {
      outerListItems: [
        { label: "Profile", icon: "mdi-account-outline", route: "MyProfile" },
        { label: "Switch Account", icon: "mdi-logout", route: "Accounts" },
        { label: "Knowledge Base", icon: "mdi-library-outline", route: "KnowledgeBase" },
        { label: "Logout", icon: "mdi-lock-outline", route: "Logout" },
      ],
      notifications: [
        {
          title: "Heads Up",
          message: "This is a sample message notification of the “Info” type",
          type: "info",
          route: "",
          time: "2021-01-15 4:47:07",
        },
        {
          title: "Warning 1",
          message:
            "This is a sample message notification of the “Warning” type",
          type: "warning",
          route: "",
          time: "2021-01-19 17:12:07",
        },
        {
          title: "Heads Up",
          message: "This is a sample message notification of the “Info” type",
          type: "info",
          route: "",
          time: "2021-01-25 1:00:07",
        },
        {
          title: "High Importance",
          message: "This is a sample message notification of High Importance",
          type: "high",
          route: "",
          time: "2020-12-15 11:57:07",
        },
        {
          title: "Heads Down",
          message: "This is a sample message notification of the “Info” type",
          type: "high",
          route: "",
          time: "2021-01-16 3:15:07",
        },
      ],
    };
  },
  computed: {
    actualOuterListItems() {
      if (this.$route.name === 'Accounts' || !this.userCanSwitchAccounts) {
        const outerListItemsCopy = [...this.outerListItems]
        outerListItemsCopy.splice(1, 1)
        return outerListItemsCopy
      }
      return this.outerListItems
    },
    userCanSwitchAccounts() {
      return this.$store.getters['user/user_info']?.accountTotal > 1 || this.$store.getters['user/isPulseAdmin'];
    }
  },
};
</script>

<style lang="scss">
.header-bar {
  z-index: 8 !important;
  .v-toolbar__content {
    background-color: white;
    border-bottom: 1px solid #dee7ee !important;
    justify-content: end;
  }

  .spacer {
    flex-grow: 0.1 !important;
  }

  @media (max-width: 728px) {
    .v-toolbar__content {
      justify-content: normal;
    }
  }

  .flex-1 {
    flex: 1 1 0%;
  }

  .w-100 {
    width: 100%;
  }
}
img.img-avatar {
  border-radius: 50%;
  object-fit: contain;
}
@media print {
  header, .v-toolbar__content {
    display: none !important;
  }
}
</style>
