<template v-once>
  <img src="@/assets/icons/healthcasts_icon.png" alt="healthcasts icon">
</template>

<script>
export default {
  name: "Healthcasts",
};
</script>

<style scoped>
</style>