<template v-once>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 384.2 384.2"
    xml:space="preserve"
  >
    <g>
      <path
        fill="#FFFFFF"
        d="M192.1,1.5C86.9,1.5,1.5,86.9,1.5,192.1c0,105.3,85.3,190.6,190.6,190.6c105.3,0,190.6-85.3,190.6-190.6   C382.7,86.9,297.4,1.5,192.1,1.5z M192.1,360.5c-93,0-168.3-75.4-168.3-168.3S99.1,23.8,192.1,23.8c93,0,168.3,75.4,168.3,168.3   S285.1,360.5,192.1,360.5z"
      />
      <path
        fill="#FFFFFF"
        d="M289.7,179h-27.3L225,225.2l-45.3-54.4c6.7-2.1,12.7-5.5,17.8-10.2c8.8-8.2,13.3-17.7,13.3-28.1   c0-10.5-4.6-19.9-13.6-27.7c-9-7.8-19.7-11.7-31.9-11.7h-63.6v137.4h23v-57.3h29.5l56.7,69l-45.5,55.1l-1.1,1.4h27.3l33-40l33.3,40   h27.3l-46.7-56.9l49.9-61.3L289.7,179z M124.9,113.1h40.6c6,0,11.1,1.9,15.6,5.9c4.5,3.9,6.7,8.4,6.7,13.5c0,5.1-2.2,9.5-6.7,13.4   c-4.6,3.9-9.7,5.9-15.7,5.9h-40.6V113.1z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "PrescriptionDataWhite",
};
</script>

<style scoped>
</style>