<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g fill="#DCEFF9" transform="translate(-287 -256) translate(287 256)">
            <circle cx="19" cy="19" r="19" />
          </g>
          <g fill="#2B84EB" fill-rule="nonzero">
            <path d="M13.795 14.414v-1.602c0-2.312-1.874-4.186-4.186-4.186H3.203c-2.312 0-4.186 1.874-4.186 4.186v1.602c0 .543.44.983.983.983.543 0 .983-.44.983-.983v-1.602c0-1.226.994-2.22 2.22-2.22H9.61c1.226 0 2.22.994 2.22 2.22v1.602c0 .543.44.983.983.983.543 0 .983-.44.983-.983zM6.406 7.389c2.312 0 4.186-1.874 4.186-4.186S8.718-.983 6.406-.983 2.22.891 2.22 3.203 4.094 7.39 6.406 7.39zm0-1.966c-1.226 0-2.22-.994-2.22-2.22 0-1.226.994-2.22 2.22-2.22 1.226 0 2.22.994 2.22 2.22 0 1.226-.994 2.22-2.22 2.22z" transform="translate(-287 -256) translate(287 256) translate(12.448 11.793)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "Person"
}
</script>

<style scoped>

</style>
