<template v-once>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fill-rule="evenodd" stroke-linejoin="round">
      <g fill-rule="nonzero" stroke="#D6E9F9">
        <g>
          <path d="M10.505 12.055c-.003-.003-.054-.01-.113-.015-.448-.042-.94-.17-1.489-.391C7.664 11.15 6.21 10.207 4.976 9.1c-.233-.21-.262-.232-.32-.254-.093-.035-.14-.03-.598.082l-.417.1H3.52c-.143 0-.237-.023-.372-.09l-.08-.038L1.56 7.396C.277 6.113.051 5.884.028 5.838.012 5.808 0 5.775 0 5.766c0-.05.064-.12.133-.146.022-.008.202-.04.4-.072l1.05-.164c.38-.06.727-.113.769-.115.2-.015.436.065.6.198.07.056.13.086.16.077l.04-.01c.021-.004.307-.284 1.181-1.157.634-.633 1.165-1.17 1.18-1.192.049-.076.035-.138-.05-.239-.082-.096-.147-.24-.178-.389-.032-.154-.036-.124.142-1.248L5.53.654c.042-.265.08-.496.086-.514.02-.06.055-.1.111-.129.031-.017.048-.015.106.013.043.02.267.24 1.538 1.509C8.458 2.618 8.87 3.036 8.9 3.082c.085.13.129.277.13.44 0 .106-.003.119-.101.528-.056.231-.101.437-.101.457.001.064.03.11.18.274.895.985 1.649 2.056 2.205 3.137.447.87.732 1.706.799 2.35.004.05.014.137.02.194.016.148.003.34-.04.564-.048.263-.15.474-.31.64-.202.214-.464.331-.833.375-.108.012-.336.021-.344.014zM8.772 9.359c.289-.059.514-.257.606-.532.026-.08.038-.157.038-.248 0-.221-.085-.42-.243-.572-.115-.11-.241-.176-.404-.21-.068-.015-.22-.015-.29 0-.157.031-.292.1-.403.208-.116.112-.186.233-.226.388-.015.06-.016.073-.016.186 0 .108.002.128.014.178.037.145.103.266.202.37.13.134.288.215.476.241.054.008.19.003.246-.009zM1.96 3.566c-.057-.034-.272-.278-.423-.478C1.112 2.523.643 1.613.411.908.312.608.276.445.274.298c0-.074.002-.09.018-.113C.327.14.346.144.587.261c.427.208.762.349 1.396.59.518.198.748.316 1.031.528.162.121.31.249.473.41.135.13.135.132.135.174 0 .03-.008.056-.027.09-.02.03-.27.289-.752.77-.74.739-.763.76-.832.76-.013 0-.036-.007-.052-.017z" transform="translate(-18 -330) matrix(1 0 0 -1 19 343)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "Rocket"
}
</script>

<style scoped>

</style>
