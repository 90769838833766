import Vuex from "vuex";
import Vue from "vue";
import { snackbar } from "@/store/snackbar";
import { user } from "@/store/user";
import { automation } from "@/store/automation";
import { mercure } from "@/store/mercure";
import createLogger from "vuex/dist/logger";
const plugins = [createLogger({})];
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    snackbar,
    user,
    automation,
    mercure,
    plugins
  },
  state: {
    count: 0,
    isLoading: false,
    drawer: false,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    startLoading(state) {
      if (state.count === 0) {
        state.isLoading = true;
      }
      state.count++;
    },
    stopLoading(state) {
      state.count--;
      if (state.count === 0) {
        state.isLoading = false;
      }
    },
    toggleMenu(state) {
      state.drawer = !state.drawer;
    },
  },
  getters: {
    loading: state => state.isLoading
  }
});
