<template>
  <v-list class="out-menu-white">
    <template v-if="title">
      <v-list-item
        class="out-menu-white__header"
        style="height: 35px; min-height: 35px"
      >
        <v-list-item-content class="py-0 pb-1">
          <v-list-item-title style="font-weight: 700">
            {{ title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>
    <v-list-item
      v-for="item in items"
      :key="item.label"
      style="height: 40px; min-height: 40px"
      link
      @click="$emit('click', { ...item })"
    >
      <template v-if="item.route">
        <a
          :href="item.route"
          onclick="return false;"
          style="display: flex; text-decoration: none;"
        >
          <v-list-item-icon v-if="item.icon" class="mr-1 mt-3">
            <v-icon size="16px" color="#241c15">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :style="{color: item.selected ? '#2b84eb': 'black'}">
              {{ item.label }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="item.selected" class="mr-1 mt-3">
            <v-icon size="16px" color="#2b84eb">
              mdi-check
            </v-icon>
          </v-list-item-icon>
        </a>
      </template>
      <template v-else>
        <v-list-item-icon v-if="item.icon" class="mr-1 mt-3">
          <v-icon size="16px" color="#241c15">
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :style="{color: item.selected ? '#2b84eb': 'black'}">
            {{ item.label }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="item.selected" class="mr-1 mt-3">
          <v-icon size="16px" color="#2b84eb">
            mdi-check
          </v-icon>
        </v-list-item-icon>
      </template>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "OuterList",
  props: {
    title: {
      default: () => {},
      type: String,
    },
    items: {
      default: () => [],
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list.out-menu-white.theme--light {
  padding-top: 5px !important;
  background-color: white !important;
  width: 200px;

  .v-list-item {
    padding: 0 10px 0 14px;
  }

  .v-list-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }

  &__header {
    .v-list-item__title {
      font-weight: 600;
    }
  }
  .v-divider.theme--light {
    background: #dee7ee;
  }
}
::v-deep {
  .menuable__content__active.v-menu__content.theme--light {
    max-width: 300px !important;
    width: 300px !important;
    min-width: 300px !important;
  }
}
</style>
