<template v-once>
  <img src="@/assets/icons/ehs_icon.png" alt="eHealthcareIcon">
</template>

<script>
export default {
  name: "Ehs",
};
</script>

<style scoped>
</style>