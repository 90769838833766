<template v-once>
  <img src="@/assets/icons/rxnt_icon.png" alt="rxnt icon">
</template>

<script>
export default {
  name: "Rxnt",
};
</script>

<style scoped>
</style>