import { render, staticRenderFns } from "./truck.vue?vue&type=template&id=554d625e&scoped=true&v-once=true"
import script from "./truck.vue?vue&type=script&lang=js"
export * from "./truck.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554d625e",
  null
  
)

export default component.exports