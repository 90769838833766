<template v-once>
  <img src="@/assets/icons/biopharm_icon.png" alt="biopharm icon">
</template>

<script>
export default {
  name: "Biopharm",
};
</script>

<style scoped>
</style>