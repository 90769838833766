<template>
  <v-btn icon @click="$store.commit('toggleMenu')">
    <v-icon>mdi-menu</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'MobileNavigationButton',
}
</script>

<style scoped>

</style>
