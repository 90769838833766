<template v-once>
  <img src="@/assets/icons/veradigm_icon.png" alt="veradigm icon">
</template>

<script>
export default {
  name: "Veradigm",
};
</script>

<style scoped>
</style>