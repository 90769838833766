<template v-once>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="13"
    viewBox="0 0 17 13"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g stroke="#2B84EB" stroke-width="1.5">
        <g transform="translate(-1191 -451) translate(1192 452)">
          <path
            d="M0 5.5S2.647 0 7.28 0c4.632 0 7.279 5.5 7.279 5.5S11.912 11 7.279 11C2.647 11 0 5.5 0 5.5z"
          />
          <ellipse cx="7.279" cy="5.5" rx="1.985" ry="2.063" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Eye",
};
</script>

<style scoped>
</style>