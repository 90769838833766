<template v-once>
  <img src="@/assets/icons/medpage_today_icon.svg" alt="medpage today icon">
</template>

<script>
export default {
  name: "MedPageToday",
};
</script>

<style scoped>
</style>