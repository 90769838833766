import { render, staticRenderFns } from "./vumedi.vue?vue&type=template&id=2b501930&scoped=true&v-once=true"
import script from "./vumedi.vue?vue&type=script&lang=js"
export * from "./vumedi.vue?vue&type=script&lang=js"
import style0 from "./vumedi.vue?vue&type=style&index=0&id=2b501930&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b501930",
  null
  
)

export default component.exports