<template v-once>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g stroke="#D6E9F9">
        <g>
          <path d="M6.5 0H1.3C.582 0 0 .582 0 1.3v10.4c0 .718.582 1.3 1.3 1.3h7.8c.718 0 1.3-.582 1.3-1.3V3.9L6.5 0z" transform="translate(-19 -413) translate(20 414)" />
          <path d="M6.5 0L6.5 3.9 10.4 3.9M7.8 7.15H2.6M7.8 9.75H2.6M3.9 4.55L3.25 4.55 2.6 4.55" transform="translate(-19 -413) translate(20 414)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "Document"
}
</script>

<style scoped>

</style>
