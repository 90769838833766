import eye from "@/assets/icons/eye"
import right_arrow from "@/assets/icons/right-arrow"
import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import home from "@/assets/icons/home"
import contacts from "@/assets/icons/contacts"
import document from "@/assets/icons/document"
import messages from "@/assets/icons/messages"
import piechart from "@/assets/icons/piechart"
import rocket from "@/assets/icons/rocket"
import truck from "@/assets/icons/truck"
import person from "@/assets/icons/person"
import check from "@/assets/icons/check"
import list from "@/assets/icons/list"
import copy from "@/assets/icons/copy"
import chevron_down_thin from "@/assets/icons/chevron-down-thin"
import puzzle from "@/assets/icons/puzzle"
import exportIcon from "@/assets/icons/exportIcon"
import google from "@/assets/icons/google"
import ubc from "@/assets/icons/ubc"
import pulsepointHcp365 from "@/assets/icons/pulsepoint_hcp365.vue"
import pulsepointlife from "@/assets/icons/pulsepointlife.vue"
import bulletin from "@/assets/icons/bulletin"
import webmd from "@/assets/icons/webmd"
import mdedge from "@/assets/icons/mdedge"
import medscape from "@/assets/icons/medscape"
import squintmetrics from "@/assets/icons/squintmetrics"
import lahlouh from "@/assets/icons/lahlouh"
import mckesson from "@/assets/icons/mckesson"
import arrowhead from "@/assets/icons/arrowhead"
import sermo from "@/assets/icons/sermo"
import doximity from "@/assets/icons/doximity"
import rxnt from "@/assets/icons/rxnt"
import prescriptionData from "@/assets/icons/prescription_data_white"
import dcc from "@/assets/icons/dcc"
import pvalue from "@/assets/icons/pvalue"
import veeva from "@/assets/icons/veeva"
import epocrates from "@/assets/icons/epocrates"
import healthcasts from "@/assets/icons/healthcasts"
import vumedi from "@/assets/icons/vumedi"
import mngHealth from "@/assets/icons/mng_health"
import mdlinx from "@/assets/icons/mdlinx"
import typeform from "@/assets/icons/typeform"
import ehs from "@/assets/icons/ehs"
import haymarket from "@/assets/icons/haymarket"
import doceree from "@/assets/icons/doceree"
import veradigm from "@/assets/icons/veradigm"
import biopharm from "@/assets/icons/biopharm"
import optimizerx from "@/assets/icons/optimizerx"
import reachmd from "@/assets/icons/reachmd"
import medPageToday from "@/assets/icons/medpagetoday"
import phreesia from "@/assets/icons/phreesia"

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      home: {
        // name of our custom icon
        component: home // our custom component
      },
      contacts: { component: contacts },
      document: { component: document },
      messages: { component: messages },
      piechart: { component: piechart },
      rocket: { component: rocket },
      truck: { component: truck },
      person: { component: person },
      check: { component: check },
      list: { component: list },
      chevron_down_thin: { component: chevron_down_thin },
      eye: { component: eye },
      copy: { component: copy },
      right_arrow: { component: right_arrow },
      puzzle: { component: puzzle },
      export: { component: exportIcon },
      google: { component: google },
      ubc: { component: ubc },
      pulsepointHcp365: { component: pulsepointHcp365 },
      pulsepointlife: { component: pulsepointlife },
      bulletin: { component: bulletin },
      webmd: { component: webmd },
      mdedge: { component: mdedge },
      medscape: { component: medscape },
      squintmetrics: { component: squintmetrics },
      lahlouh: { component: lahlouh },
      mckesson: { component: mckesson },
      arrowhead: { component: arrowhead },
      sermo: { component: sermo },
      doximity: { component: doximity },
      prescriptionData: { component: prescriptionData },
      rxnt: { component: rxnt },
      dcc: { component: dcc },
      pvalue: { component: pvalue },
      veeva: { component: veeva },
      epocrates: { component: epocrates },
      healthcasts: { component: healthcasts },
      vumedi: { component: vumedi },
      mngHealth: { component: mngHealth },
      mdlinx: { component: mdlinx },
      typeform: { component: typeform },
      ehs: {component: ehs},
      haymarket: { component: haymarket },
      doceree: { component: doceree },
      veradigm: { component: veradigm },
      biopharm: { component: biopharm },
      optimizerx: { component: optimizerx },
      reachmd: { component: reachmd },
      medPageToday: { component: medPageToday },
      phreesia: { component: phreesia },
    },
  },
})
