<template v-once>
  <img src="@/assets/icons/pulsepoint_life_icon.png" alt="Pulse Point HCP365">
</template>

<script>
export default {
  name: "PulsePointHCP365",
};
</script>

<style scoped>
</style>
