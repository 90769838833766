export const snackbar = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$notifier = {
      showMessage({ content = "", color = "" }) {
        options.store.commit("snackbar/showMessage", { content, color });
      },
      success(content) {
        options.store.commit("snackbar/showMessage", { content, color: "success" });
      },
      error(content) {
        options.store.commit("snackbar/showMessage", { content, color: "error" });
      },
      info(content) {
        options.store.commit("snackbar/showMessage", { content, color: "info" });
      },
      warning(content) {
        options.store.commit("snackbar/showMessage", { content, color: "warning" });
      },
    };
  }
};
