import { render, staticRenderFns } from "./veradigm.vue?vue&type=template&id=2dd17f44&scoped=true&v-once=true"
import script from "./veradigm.vue?vue&type=script&lang=js"
export * from "./veradigm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd17f44",
  null
  
)

export default component.exports