<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g fill="#2B84EB" transform="translate(-767 -264) translate(767 264)">
            <circle cx="11" cy="11" r="11" />
          </g>
          <g stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
            <path d="M10.273 0L3.21 7.062 0 3.852" transform="translate(-767 -264) translate(767 264) translate(6 8)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "Check"
}
</script>

<style scoped>

</style>
