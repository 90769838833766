<template v-once>
  <img src="@/assets/icons/typeform_icon.png" alt="typeform icon">
</template>

<script>
export default {
  name: "Typeform",
};
</script>

<style scoped>
</style>
