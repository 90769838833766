<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g transform="translate(-843 -256) translate(843 256)">
          <circle cx="19" cy="19" r="19" fill="#DCEFF9" />
          <g fill="#2B84EB" fill-rule="nonzero">
            <path d="M4.552 1.741h9.862c.543 0 .983-.44.983-.982 0-.543-.44-.983-.983-.983H4.552c-.543 0-.983.44-.983.983 0 .542.44.982.983.982zM4.552 6.293h9.862c.543 0 .983-.44.983-.983 0-.542-.44-.982-.983-.982H4.552c-.543 0-.983.44-.983.982 0 .543.44.983.983.983zM4.552 10.845h9.862c.543 0 .983-.44.983-.983 0-.543-.44-.983-.983-.983H4.552c-.543 0-.983.44-.983.983 0 .543.44.983.983.983zM.759 1.741c.55 0 .99-.44.99-.982 0-.498-.37-.91-.85-.974l-.14-.01c-.543 0-.983.44-.983.984 0 .542.44.982.983.982zM.759 6.293c.55 0 .99-.44.99-.983 0-.497-.37-.908-.85-.973l-.14-.01c-.543 0-.983.44-.983.983 0 .543.44.983.983.983zM.759 10.845c.55 0 .99-.44.99-.983 0-.497-.37-.909-.85-.974L.76 8.88c-.543 0-.983.44-.983.983 0 .543.44.983.983.983z" transform="translate(11.793 14.414)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "List"
}
</script>

<style scoped>

</style>
