<template v-once>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" class="right-arrow">
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g stroke-width="1.5">
        <g>
          <g>
            <path d="M0 5h10M5 0L10 5 5 10" transform="translate(-1333 -476) translate(267 357) translate(1067 120)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "RightArrow",
};
</script>

<style scoped>
.g {
  stroke: #2B84EB
}

.right-arrow g {
  stroke: currentColor;
}
</style>