<template v-once>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12">
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g stroke="#D6E9F9">
        <g transform="translate(-18 -162) translate(19 163)">
          <path d="M9.455 10.636V9.455c0-1.306-1.059-2.364-2.364-2.364H2.364C1.058 7.09 0 8.149 0 9.455v1.181" />
          <circle cx="4.727" cy="2.364" r="2.364" />
          <path d="M13 10.636V9.455c0-1.078-.73-2.018-1.773-2.287M8.864.077c1.045.268 1.777 1.21 1.777 2.29s-.732 2.022-1.777 2.29" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "Contacts"
}
</script>

<style scoped>

</style>
