<template v-once>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g stroke="#D6E9F9">
        <g>
          <path d="M0 4.511L5.8 0l5.8 4.511V11.6c0 .712-.577 1.289-1.289 1.289H1.29C.577 12.889 0 12.312 0 11.6V4.511z" transform="translate(-19 -118) translate(20 119)" />
          <path d="M3.867 12.889L3.867 6.444 7.733 6.444 7.733 12.889" transform="translate(-19 -118) translate(20 119)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "DashboardIcon"
}
</script>

<style scoped>

</style>
