<template v-once>
  <img src="@/assets/icons/pvalue_icon.png" alt="p-value">
</template>

<script>
export default {
  name: "Pvalue",
};
</script>

<style scoped>
</style>