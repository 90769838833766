<template>
  <div>
    <div
      v-if="$store.getters['user/account'].logo"
      :key="$store.getters['user/account'].logo"
      class="d-flex"
    >
      <v-img max-width="100px" max-height="23px" contain :src="$store.getters['user/account'].logo" class="py-3" />
    </div>
    <div v-else class="account-name mb-1">
      {{ $store.getters['user/account'].name }}
    </div>
  </div>
</template>

<script>
export default {
name: "AccountImage"
}
</script>

<style lang="scss">
.account-name {
  font-family: "Playfair Display", serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #241c15;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

@media (min-width: 727px) {
  .account-name {
    max-width: 500px;
  }
}
</style>
