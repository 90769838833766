<template>
  <v-list-group v-model="active" :prepend-icon="icon" class="menu-desktop-collapse-item">
    <template #activator>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </template>
    <template #appendIcon>
      <v-icon>
        $chevron_down_thin
      </v-icon>
    </template>
    <template
      v-for="subroute in items"
    >
      <a 
        v-if="subroute.canView == undefined || subroute.canView"
        :key="subroute.title"
        :href="getHref(subroute.path, subroute.params)"
        onclick="return false;"
      >
        <v-list-item
          light
          class="nav-subitem"
          link
          :class="{
            'link-selected': subroute.path === $route.name,
          }"
          @click="$router.push({ name: subroute.path, params: subroute.params })"
        >
          <v-list-item-content>
            <v-list-item-title style="color: #d6e9f9">
              {{ subroute.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>
    </template>
  </v-list-group>
</template>

<script>
export default {
  name: "MenuDesktopCollapseItem",
  props: {
    title: {
      default: "",
      type: String,
    },
    icon: {
      default: "",
      type: String,
    },
    items: {
      default: () => [],
      type: Array,
    },
  },
  data(){
    return {
      active:false
    }
  },
  created(){
    for(var i =0; i<this.items.length;i++)
      if(this.items[i].path === this.$route.name)
        this.active = true
  },
  methods: {
    getHref(name, params) {
      return this.$router.resolve({ name , params }).href;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: unset;
}
</style>

<style lang="scss">
.v-list-group__header__prepend-icon .v-icon .v-icon__svg {
  height: 13px !important;
  width: 13px !important;
}
.v-list-group.menu-desktop-collapse-item
  > .v-list-group__header
  > .v-list-group__header__append-icon {
  min-width: 0px !important;
  display: flex;
  align-items: center;
  .v-icon {
    transform: rotate(90deg);
  }
}

.v-list-group--active.menu-desktop-collapse-item
  > .v-list-group__header
  > .v-list-group__header__append-icon
  .v-icon {
  transform: rotate(0deg);
}

.menu-desktop-collapse-item {
  .nav-subitem.v-list-item.v-list-item--link {
    min-height: 35px;
    margin: 2.5px 8px;
    border-radius: 6px;
    .v-list-item__content {
      margin-left: -3px;
    }
  }
  .nav-subitem.v-list-item.v-list-item--link.link-selected {
    background-color: rgba(0, 0, 0, 0.16);
  }
}
</style>
