<template v-once>
  <img src="@/assets/icons/arrowhead_icon.png" alt="arrowhead icon">
</template>

<script>
export default {
  name: "Arrowhead",
};
</script>

<style scoped>
</style>