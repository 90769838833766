<template>
  <div v-if="routeName">
    <a v-for="(menuItem, index) in menuItems"
       :key="index"
       href="#"
       class="menu-item active"
    >
      {{ menuItem.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: "NavigationDuplicate",
  data: () => ({
    routeName: "",
  }),
  computed: {
    menuItems() {
      return [];
    },
  },
  watch: {
    '$route.name': {
      handler(val) {
        this.routeName = val;
      },
    },
  },
  created() {
    this.routeName = this.$route.name;
  },
  methods: {
    createMenuItem(name, icon) {
      return {
        name,
        icon, // TODO: Do we need icon??
      }
    },
  },
}
</script>

<style scoped>
  .menu-item {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2b84eb;
    padding-top: 17px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
  }
  .menu-item.active {
    border-bottom: 2px #2b84eb solid;
  }
</style>
