<template v-once>
  <img src="@/assets/icons/mdlinx_icon.png" alt="mdlinx icon">
</template>

<script>
export default {
  name: "MDLinx",
};
</script>

<style scoped>
</style>