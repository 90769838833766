import store from "../store/index";

/**
 * Checks to see if the point provided is in the node
 *
 * @param point    - an Object of X and Y coordinates
 * @param offset   - this adds width to all sides of the node to act as a buffer
 * @param position - the position of the node
 */
const pointIsInNode = (point, offset, position) => {
  const width = 192;
  const height = 120;

  // Get the range of x values that this node takes up
  const startXRange = position.x - Math.round(width / 2) - offset;
  const xBoundary = [startXRange - 8, startXRange + width + offset * 2 + 8];

  // Get the range of y values that this node takes up
  const startYRange = position.y - Math.round(height / 2) - offset;
  const yBoundary = [startYRange - 8, startYRange + height + offset * 2 + 8];

  // The point is in the node if it's within the x and y boundaries
  return !(
    point.x < xBoundary[0] ||
    point.x > xBoundary[1] ||
    point.y < yBoundary[0] ||
    point.y > yBoundary[1]
  );
};

/**
 * Get the intersection point between the start node's edge and a line going to the specified target position
 *
 * @param startPos     - an object with the X and Y coordinates of the transition's start
 * @param targetPos    - an object with the X and Y coordinates of the transition's target
 * @param offset       - add width to the target node in all directions (optional)
 *
 * @returns Object
 */
export const getOuterPositionForTransition = (startPos, targetPos, offset) => {
  // how far away from the center of the start node to look
  // this should be greater than the width of any node in the canvas
  const testRadius = 300;
  offset = offset ? offset : 0;

  // By default the most outer point is the start position
  let mostOuterPoint = { x: targetPos.x, y: targetPos.y };

  // Get the distance between the start and target positions
  const diffX = startPos.x - targetPos.x;
  const diffY = startPos.y - targetPos.y;
  const distance = Math.sqrt(diffX * diffX + diffY * diffY);

  // Test if the point that is n pixels away from the start position
  // is not in the start node. The first point that is not in the node
  // is considered the most outer point along the line between the start
  // and target nodes, or the node's edge
  for (let n = 0; n < testRadius; n++) {
    // Calculate how far the x and y values are changing along the line
    const velocityX = (diffX / distance) * n;
    const velocityY = (diffY / distance) * n;

    const testX = targetPos.x + velocityX;
    const testY = targetPos.y + velocityY;

    const test = { x: testX, y: testY };

    // Check if the point is in the node
    if (!pointIsInNode(test, offset, targetPos)) {
      mostOuterPoint = test;
      break;
    }
  }

  return mostOuterPoint;
};

export const checkForHorizontalSnap = (
  nodes,
  nodeBeingDragged,
  snapRange,
  returnFirstSnappableNode
) => {
  let canSnapHorizontally = false;

  // Check the nodes in the canvas currently
  if (Object.keys(nodes).length && !!nodeBeingDragged) {
    const nodeCenterY = nodeBeingDragged.position.y;

    for (let nuid in nodes) {
      if (nuid !== nodeBeingDragged.nuid) {
        const node = nodes[nuid];

        // Check if the node to snap is within the snap range of the node being checked
        if (
          nodeCenterY - snapRange < node.position.y &&
          node.position.y < nodeCenterY + snapRange
        ) {
          if (returnFirstSnappableNode) {
            return node;
          }

          canSnapHorizontally = true;
          break;
        }
      }
    }

    // Sometimes the code requires the node it can snap to so return it
    return canSnapHorizontally;
  }
};

export const checkForVerticalSnap = (
  nodes,
  nodeBeingDragged,
  snapRange,
  returnFirstSnappableNode
) => {
  let canSnapVertically = false;

  if (Object.keys(nodes).length && !!nodeBeingDragged) {
    const nodeCenterX = nodeBeingDragged.position.x;

    for (let nuid in nodes) {
      if (nuid !== nodeBeingDragged.nuid) {
        const node = nodes[nuid];

        // Check if the node to snap is within the snap range of the node being checked
        if (
          nodeCenterX - snapRange < node.position.x &&
          node.position.x < nodeCenterX + snapRange
        ) {
          if (returnFirstSnappableNode) {
            return node;
          }

          canSnapVertically = true;
          break;
        }
      }
    }
  }

  return canSnapVertically;
};

/**
 * Finds the angle between the points provided
 *
 * @param startX - the x position of the line's start point
 * @param startY - the y position of the line's start point
 * @param endX   - the x position of the line's end point
 * @param endY   - the y position of the line's end point
 *
 * @return {int} - the angle in degrees (not radians)
 */
export const getAngleBetweenPoints = (startX, startY, endX, endY) => {
  const diffY = endY - startY;
  const diffX = endX - startX;
  let theta = Math.atan2(diffY, diffX); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]

  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
};

export const transitionCreatesInfiniteLoop = (newTransition, nodes) => {
  // Just in case, let's check the target exists
  if (!newTransition.targetNode.nuid) {
    return {};
  }

  let transitionStartNodeId = newTransition.startNode.nuid;

  // Parse and unparse to create a deep copy of this nested object
  let currentNodes = JSON.parse(JSON.stringify(nodes));

  // Create temporary function to use for recursion
  function checkIfPathLeadsToStartNode(nodeId) {
    if (!currentNodes[nodeId].transitions
      || Object.keys(currentNodes[nodeId].transitions).length === 0
    ) {
      // The node being checked doesn't have any transitions so this path is valid
      return false;
    }

    for (let i in currentNodes[nodeId].transitions) {
      const transition = currentNodes[nodeId].transitions[i];

      if (transition.next === transitionStartNodeId) {
        // A transition for this node is the start node of the transition being created
        // So this means there's an infinite loop
        return true;
      }

      // Check the paths of the transition
      const createsInfiniteLoop = checkIfPathLeadsToStartNode(transition.next);

      if (createsInfiniteLoop) {
        // Break out of the loop as soon as there's a true value
        return createsInfiniteLoop;
      }
    }

    // This node is valid
    return false;
  }

  return checkIfPathLeadsToStartNode(newTransition.targetNode.nuid);
};

export const runAutomationHooks = (
  { nodes, transitions, hooks, commit },
  hookFunctionParams,
  successCallback,
  errorCallback
) => {
  let hookPromises = [];

  for (let nuid in hooks) {
    const hookFunction = hooks[nuid];

    // Hook function must return a promise
    hookPromises.push(
      hookFunction(nuid, nodes, transitions, ...hookFunctionParams)
    );
  }

  Promise.all(hookPromises)
    .then(allMutations => {
      if (allMutations.length) {
        for (let i in allMutations) {
          const mutations = allMutations[i];

          for (let j in mutations) {
            commit(mutations[j].mutation, mutations[j].payload);
          }
        }
      }

      successCallback(allMutations);
    })
    .catch(err => {
      console.error(err);
      store.commit('snackbar/showMessage', {
        content: err,
        color: 'error',
      });
      errorCallback(err);
    });
};
