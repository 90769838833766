<template v-once>
  <img src="@/assets/icons/webmd_icon.png" alt="webmd icon">
</template>

<script>
export default {
  name: "WebMD",
};
</script>

<style scoped>
</style>