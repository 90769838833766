<template v-once>
  <img src="@/assets/icons/mng_health_icon.png" alt="mng health icon">
</template>

<script>
export default {
  name: "MngHealth",
};
</script>

<style scoped>
</style>