import { rest } from '@/api/rest.api';
import store from "@/store";

class AuthHelper {
  static isAuthenticated() {
    return localStorage.getItem("authenticated") === "true";
  }

  static async isAuthenticated2fa() {
    if (store.getters["user/user_info"].twoFaExpirationDate === undefined) {
      const user_details = (await rest.user.get_user_details()).data;
      localStorage.setItem('user', JSON.stringify(user_details));
      store.commit('user/update_user_info', {
        ...user_details
      });
    }
    const twoFaExpirationDate = store.getters["user/user_info"].twoFaExpirationDate;

    return twoFaExpirationDate && new Date().getTime() < new Date(twoFaExpirationDate).getTime();
  }
}

export default AuthHelper;