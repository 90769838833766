<template v-once>
  <img src="@/assets/icons/phreesia_icon.png" alt="Phreesia">
</template>

<script>
export default {
  name: "Phreesia",
};
</script>

<style scoped>
</style>
