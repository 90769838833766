<template>
  <a :href="resolvedRoute.href" onclick="return false;">
    <v-list-item
      class="plain-menu"
      link
      :class="{
        'link-selected': path === $route.name,
      }"
      @click="$router.push({ name: path, params })"
    >
      <v-list-item-icon>
        <v-icon color="#d6e9f9" size="13px">
          {{ icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="color: #d6e9f9">
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </a>
</template>

<script>
export default {
  name: "MenuPlainItem",
  props: {
    title: {
      default: "",
      type: String,
    },
    icon: {
      default: "",
      type: String,
    },
    path: {
      default: "",
      type: String,
    },
    params: {
      type: Object || undefined,
      default: () => undefined,
    },
  },
  computed: {
    resolvedRoute() {
      return this.$router.resolve({name: this.path, params: this.params});
    }
  }
};
</script>

<style scoped>
.link-selected {
  background-color: rgba(0, 0, 0, 0.16);
}

a {
  text-decoration: unset;
}
</style>
